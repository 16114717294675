import React, { Component } from 'react';
import { TextContainer, TextStyle, Card, Select, Popover, ActionList, Link, Collapsible, Button, Badge, Layout, Icon, Stack } from '@shopify/polaris';
import navigations from '../Json/navigations.json';
import store from 'store2';
import FAQ from './FAQ';
import CommonHelper from '../Helpers/Common';
import PageTitleBar from '../Common/TitleBar';
import Common from '../Helpers/Common';
import { TickSmallMinor } from '@shopify/polaris-icons';
import {LinkOverride} from '../Helpers/LinkOverride';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;
const nav_list = navigations["nav_1"]["238"];
const nav_list_2 = navigations["nav_2"]["238"];
const static_addons = navigations["nav_2"]["238_addons"];

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extra_featured_collapsed: false,
      extra_features: [
        {
          title: "NET TERMS",
          description: "Allow customers to create an unpaid order directly from their cart.",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360045551651",
          link_label: "Read more"
        },
        {
          title: "QUICK ORDER FORM",
          description: "Provide frequent purchasers with a simple, single-page order form.",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360045821571",
          link_label: "Read more"
        },
        {
          title: "ADVANCED MINIMUMS",
          description: "Set minimum quantity requirements per variant, product, collection or entore store.",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360045823331",
          link_label: "Read more"
        },
        {
          title: "Manage Lock",
          description: "Show/Hide products to specific users",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360054881511",
          link_label: "Read more"
        },
        {
          title: "Bulk Import/Export",
          description: "Upload wholesale pricing in bulk via CSV file",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360054559691",
          link_label: "Read more"
        },
        {
          title: "LOGIN TO VIEW PRICES",
          description: "Hide price from those customers who are not logged in.",
          link_url: "https://support.digitalcoo.com/hc/en-us/articles/360054438812",
          link_label: "Read more"
        }
      ],
      version_select_open: false,
    }
  }

  basicPlan = false;
  redirectToPath(x){
    if(x.exactURL){
      this.props.linkOverride.push(x.exactURL);
    }
    if(x.pathToUpgrade){
      this.props.linkOverride.push('/unlock');
    }
    return;
 }

  isBasicPlan(){
    return Common.getPlanID() === 1;
  }

  getPlanName(){
    var name = null;
    if(this.props.shopData && this.props.shopData.shop_payment){
      if(this.props.shopData.shop_payment.plan_name){
        name = this.props.shopData.shop_payment.plan_name;
      }
    }
    return name;
  }

  hasFeatures(value){
    var hasF = false;
    if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
      hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
    }
    return hasF;
  }

  // [TEMP] Check if shop_features called "new_addons" enabled
  enableNewAddon(){
    return true; // Enabled for all
    // return this.hasFeatures("new_addons");
  }

  versionSelected(v){
    store.remove('snippet_version');
    store('ignore_live_file',true);
    store("wsv_stc", "1");
    window.location.replace(window.location.pathname+"?shop="+this.props.shop+"&wsvv="+v);
  }

  render() {
    var list = null;
    const _idAdmin = CommonHelper.isAdmin();
    if(nav_list && nav_list.length){
      list = nav_list.map((x,k)=>{
          var lockImage = false;
          var isDisabled = x.disabled;
          var external = "";
          if(x.external){
            external = '_blank';
          }
          var addStyleClass = "";
          if(x.url !== (BUILD_VERSION_EXTENSION+"/pricing-groups") && x.url !== (BUILD_VERSION_EXTENSION+"/quantity-breaks")){
            addStyleClass = "custom-nav-font-color";
          }
          if(x.url === (BUILD_VERSION_EXTENSION+"/pricing-groups") || x.url === (BUILD_VERSION_EXTENSION+"/quantity-breaks")){
            x.exactURL = x.url;
            x.url = "#";
          }
          if(Common.getPlanID() === 1 && (x.url === (BUILD_VERSION_EXTENSION+"/min-order-control") || x.url === (BUILD_VERSION_EXTENSION+"/access-control") || x.url === (BUILD_VERSION_EXTENSION+"/shipping") || x.url === (BUILD_VERSION_EXTENSION+"/volume-discount/list"))){
            lockImage = true;
          }
          if(Common.getPlanID() !== 3 && (x.url === (BUILD_VERSION_EXTENSION+"/cart-discounts/list"))){
            lockImage = true;
          }
          if(x.url === (BUILD_VERSION_EXTENSION+"/pricing-plans") || x.url === (BUILD_VERSION_EXTENSION+"/update-plans")){
            if(this.props.shopData && this.props.shopData.shop_payment && this.enableNewAddon()){
              // Some plan is subscribed, update url to update plan page
              x.url = BUILD_VERSION_EXTENSION+"/update-plans";
            }
          }
          return <li key={k} className={"area-settings-nav__item"}>
          <LinkOverride target={external} className={`area-settings-nav__action`} onClick={()=>{this.redirectToPath(x)}} href={x.url} aria-disabled={isDisabled} >
            <div className="area-settings-nav__media">
              <img src={x.img_src} alt={x.title}></img>
            </div>
            <div>
              <div className="main-nav-title">
                <p className={`area-settings-nav__title ${addStyleClass}`}>
                    {x.title}
                </p>
                {
                  lockImage? 
                  <span>
                    <img className="icon-lock" width="25px" height="18px" src='/icons/lock.svg' alt="lock-icon"></img>
                  </span>
                  :null
                }
              </div>
              <p className="area-settings-nav__description">
                {x.description}
              </p>
            </div>
          </LinkOverride>
        </li>;
      });
    }
    var qc_enabled = this.hasFeatures("quantity_controller");
    var netTerm_enabled = this.hasFeatures("net_term");
    var quick_order_form = this.hasFeatures("quick_order_form");
    var managelock = this.hasFeatures("managelock");
    var list_2 = [];
    var show_adOns = false;
    if(nav_list_2 && nav_list_2.length){
      list_2 = nav_list_2.map((x,k)=>{
          var lockImage = false;
          var isDisabled = x.disabled;
          if( (x.url === (BUILD_VERSION_EXTENSION+"/quantity-controller")) && !(qc_enabled)){
            return false;
          }
          if((x.url === (BUILD_VERSION_EXTENSION+"/net-terms")) && !(netTerm_enabled)){
            return false;
          }
          if((x.url === (BUILD_VERSION_EXTENSION+"/quick-order-forms")) && !(quick_order_form)){
            return false;
          }
          if((x.url === (BUILD_VERSION_EXTENSION+"/manage-locks")) && !(managelock)){
            return false;
          }
          var external = "";
          if(x.external){
            external = '_blank';
          }
          var addStyleClass = "";
          if(x.url !== (BUILD_VERSION_EXTENSION+"/pricing-groups") && x.url !== (BUILD_VERSION_EXTENSION+"/quantity-breaks")){
            addStyleClass = "custom-nav-font-color";
          }
          if(x.url === (BUILD_VERSION_EXTENSION+"/pricing-groups") || x.url === (BUILD_VERSION_EXTENSION+"/quantity-breaks")){
            x.exactURL = x.url;
            x.url = "#";
          }
          if(Common.getPlanID() === 1){
            lockImage = true;
          }
          if(
            x.url !== (BUILD_VERSION_EXTENSION+"/min-order-control") &&
            x.url !== (BUILD_VERSION_EXTENSION+"/access-control")  &&
            x.url !== (BUILD_VERSION_EXTENSION+"/shipping")
          ){
            lockImage = false;
          }
          var subscribed_plan = null;
          if(x.url === (BUILD_VERSION_EXTENSION+"/pricing-plans") || x.url === (BUILD_VERSION_EXTENSION+"/update-plans")){
            subscribed_plan = this.getPlanName();
          }
          show_adOns=true;
          return <li key={k} className={"area-settings-nav__item border-bottom"}>
          <LinkOverride target={external} className={`area-settings-nav__action`} onClick={()=>{this.redirectToPath(x)}} href={x.url} aria-disabled={isDisabled} >
            <div className="area-settings-nav__media">
              <img src={x.img_src} alt={x.title}></img>
            </div>
            <div>
              <div className="main-nav-title">
                <p className={`area-settings-nav__title ${addStyleClass}`}>
                    {x.title} <span className="subscribed-plan-name">{subscribed_plan?`(${subscribed_plan})`:null}</span>
                </p>
                {
                  lockImage? 
                  <span>
                  <img className="icon-lock" width="25px" height="18px" src='/icons/lock.svg' alt="lock-icon"></img>
                  </span>
                  :null
                }
              </div>
              <p className="area-settings-nav__description">
                {x.description}
              </p>
            </div>
          </LinkOverride>
        </li>;
      });
    }
    var extraFeatureSection = this.state.extra_features.map((x,k) => {
      if(!x.title){ return false; }
      return <Card.Section title={x.title} key={'ex-fea-'+k}>
          {x.description} <Link external={true} url={x.link_url}>{x.link_label}</Link>
        </Card.Section>
    });
    let addonStyles = {display: "grid"};
    if(window.innerWidth > 1025){
      addonStyles['gridTemplateColumns'] = "1fr 1fr 1fr 1fr";
      addonStyles['gap'] = "1.2rem";
    }
      return (
        <div>
          <Button 
            onClick = {() => {
              store.clearAll();
            }}
          >
            Click
          </Button>
          <PageTitleBar
            title="Dashboard"
          />
          <Card sectioned>
              <ul className="area-settings-nav">
                  {list}
              </ul>
          </Card>
  
          
  
          {this.enableNewAddon() ? 
  
            <Card 
              title="Add-on Features"
              actions={[
                {
                    content: "How do addons work?",
                    url: "https://support.digitalcoo.com/hc/en-us/sections/360009450951",
                    external:true,
                }
              ]}
            >
  
              {
                static_addons.filter( x => x.features.some(x=>this.hasFeatures(x)) ).length > 0 ?
                  <Card.Section title="Active Add-ons">
  
                    <div className='addon-styles' style={addonStyles}>
  
                    {
                      static_addons
                      .filter( x => x.features.some(x=>this.hasFeatures(x)) ) // ENABLED ADDONS
                      .map((static_addon, index) => {
  
                        let addon_enabled = static_addon.features.some(x=>this.hasFeatures(x));
  
                        return (<div className='addon-style'>
                          <Stack spacing="extraTight">
                            <Stack.Item>
                              <Stack spacing="extraTight">
                                <LinkOverride style={{padding: "0"}} className='area-settings-nav__action' target={static_addon.tag === 'import_export' ? "_blank" : ''} href={(static_addon.component_link && static_addon.component_link !== "") ? static_addon.component_link : static_addon.tag === 'import_export' ? 'https://support.digitalcoo.com/hc/en-us/articles/360054559691' : BUILD_VERSION_EXTENSION+"/update-plans" }>
                                <div className="area-settings-nav__media">
                                  <img src={static_addon.img_src} alt={static_addon.title}></img>
                                </div>
                                  <TextContainer>
                                    <TextStyle>
                                      <span>{static_addon.title}</span>
                                      <p className="Polaris-Caption" style={{maxWidth: "19.8rem"}}>
                                        {static_addon.description}
                                      </p>
                                    </TextStyle>
                                  </TextContainer>
                                </LinkOverride>
                                {/*                                 
                                  <Stack spacing="extraTight">
                                    {
                                      addon_enabled ? 
  
                                      <>
                                        {static_addon.component_link && static_addon.component_link !== "" && <Button plain url={static_addon.component_link}>Manage Addon</Button>}
                                      </>
                                      : 
                                      <Button plain url={BUILD_VERSION_EXTENSION+"/update-plans"}>Activate</Button> 
                                    }
                                  </Stack>
                                 */}
                              </Stack>
                            </Stack.Item>
                            {!addon_enabled ? <Badge>Inactive</Badge> : null}
                          </Stack>
                          </div>);
                      })
                    }
  
                    </div>
                    
                  </Card.Section>
  
              : null }
  
              {
                static_addons.filter( x => !x.features.some(x=>this.hasFeatures(x)) ).length > 0 ?
                <Card.Section title="Inactive Add-ons">
  
                  <div className='addon-styles' style={addonStyles}>
  
                  {
                    static_addons
                    .filter( x => !x.features.some(x=>this.hasFeatures(x)) ) // DISABLED ADDONS
                    .map((static_addon, index) => {
                      return (<div className='addon-style'>
                        <Stack spacing="extraTight">
                          <Stack.Item>
                            <Stack spacing="extraTight">
                              <LinkOverride style={{padding: "0"}} className='area-settings-nav__action' target={static_addon.tag === 'import_export' ? "_blank" : ''}  href={(static_addon.component_link && static_addon.component_link !== "") ? static_addon.component_link : static_addon.tag === 'import_export' ? 'https://support.digitalcoo.com/hc/en-us/articles/360054559691' : BUILD_VERSION_EXTENSION+"/update-plans" }>
                              <div className="area-settings-nav__media">
                                <img src={static_addon.img_src} alt={static_addon.title}></img>
                              </div>
                                <TextContainer>
                                  <TextStyle>
                                    <span>{static_addon.title}</span>
                                    <p className="Polaris-Caption" style={{maxWidth: "15.8rem"}}>
                                      {static_addon.description}
                                    </p>
                                  </TextStyle>
                                </TextContainer>
                              </LinkOverride>
                            </Stack>
                          </Stack.Item>
                          <Badge>Inactive</Badge>
                        </Stack>
                        </div>);
                    })
                  }
  
                  </div>
  
                </Card.Section>
  
              : null }
  
            </Card>
          :
          <Card 
            title="Add-on Features"
            actions={[
              {
                  content: "How do addons work?",
                  url: "https://support.digitalcoo.com/hc/en-us/sections/360009450951",
                  external:true,
              }
            ]}
          >
          {
              (nav_list_2.length > 0) && show_adOns && (list_2) && (<div className="navbar-2">{list_2}</div>)
            }
            <div className="collapsible-item">
              <Card.Section>
                <div className="collapsible-item-header">
                    <Button plain id="text-left"
                        onClick={() => { 
                          // CHeck if no add-ons enabled, redirect to new add-on subscription page
  
                          this.setState({extra_featured_collapsed:!this.state.extra_featured_collapsed})
                        }}
                      ariaExpanded={this.state.extra_featured_collapsed}
                    >
                    View list of all feature additions like Net Terms, Quick Order form, Advanced Minimums, Login to view price, Manage Lock and bulk Import/export for a one-time installation fee. <b style={{textDecoration:"underline"}}>Click here</b>
                    </Button>
                </div>
              </Card.Section>
              <Collapsible open={this.state.extra_featured_collapsed} id="basic-collapsible">
                  {extraFeatureSection}
                </Collapsible>
            </div>
          </Card>
          }
          <div style={{margin: "2rem 0"}}>
            <FAQ />
          </div>
          <div className="app-version-link" style={{margin:"2rem 0"}}>
            {
            (this.props.shopData && this.props.shopData.app_version) && (
              <Stack spacing="loose" alignment="center">
                <div>
                  <Badge status="info">
                  App version: {Common.appVersion(this.props.shopData.app_version)}
                  </Badge>
                </div>
                {
                  _idAdmin &&
                  <div>
                    {/* <span style={{margin:'1.2rem', borderLeft:'1px dashed #5f5f5f'}}></span> */}
                    <Button
                      onClick={()=> {
                        store.remove("snippet_version");
                        store.remove("ignore_live_file");
                        store.remove("wsv_stc");
                        window.location.replace("/?cache=clear&shop="+this.props.shop);
                      }}
                    > Store's default version </Button>
                  </div>
                }
                {
                  _idAdmin && <div className="ws--version_popup">
                    <Popover
                      active={this.state.version_select_open}
                      activator={<Button
                        disclosure
                        onClick={() => {
                          this.setState({version_select_open: !this.state.version_select_open});
                        }}
                      >
                        Choose API version
                      </Button>}
                      onClose={() => {
                        this.setState({version_select_open: !this.state.version_select_open});
                      }}
                    >
                      <ActionList
                        items={[
                          {
                            content: <span>v 2.3.8 <Badge status="info">Latest</Badge></span>,
                            onAction: () => {
                              this.versionSelected("2.3.8");
                            },
                            suffix: store('snippet_version') === "2.3.8"?<Icon source={TickSmallMinor} />:null,
                            active: store('snippet_version') === "2.3.8",
                          },
                          {
                            content: <span>v 2.3.7 </span>,
                            onAction: () => {
                              this.versionSelected("2.3.7");
                            },
                            suffix: store('snippet_version') === "2.3.7"?<Icon source={TickSmallMinor} />:null,
                            active: store('snippet_version') === "2.3.7",
                          },
                          {
                            content: <span>v 2.3.6 </span>,
                            onAction: () => {
                              this.versionSelected("2.3.6");
                            },
                            suffix: store('snippet_version') === "2.3.6"?<Icon source={TickSmallMinor} />:null,
                            active: store('snippet_version') === "2.3.6",
                          },
                          {
                            content: <span>v 2.3.5 <Badge status="attention">Unsupported</Badge></span>,
                            onAction: () => {
                              this.versionSelected("2.3.5");
                            },
                            suffix: store('snippet_version') === "2.3.5"?<Icon source={TickSmallMinor} />:null,
                            active: store('snippet_version') === "2.3.5",
                          }
                        ]}
                      />
                    </Popover>
                  </div>
                }
              </Stack>)
            }
          </div>
          {
            (false && _idAdmin) && <div style={{marginBottom:'1rem'}}>
              <Layout>
                <Layout.Section oneHalf>
                  <Card>
                    <Card.Section>
                        <Select
                          label="Select Version"
                          value={store('snippet_version') || ""}
                          options={[
                            { label: "Select a version", value: "", disabled: true},
                            { label: "v2.3.8 (Latest)", value: "2.3.8"},
                            { label: "v2.3.7 (Supported)", value: "2.3.7"},
                            { label: "v2.3.6 (Supported)", value: "2.3.6"},
                            { label: "v2.3.5 (Unsupported)", value: "2.3.5"}
                          ]}
                          onChange={(v)=>{
                            store.remove('snippet_version');
                            store('ignore_live_file',true);
                            store("wsv_stc", "1");
                            window.location.replace(window.location.pathname+"?shop="+this.props.shop+"&wsversion="+v+"&wsvv="+v);
                          }}
                        ></Select>
                    </Card.Section>
                  </Card>
                </Layout.Section>
                <Layout.Section oneHalf></Layout.Section>
              </Layout>
            </div>
          }
        </div>
      );
    }
}