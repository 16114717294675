import React, { Component } from "react";
import {Stack, Banner, Spinner, Button } from '@shopify/polaris';
import Axios from './Axios';
import SkeletonPage from './Components/SkeletonPageComp';
import Routing from './Routing';
import store from 'store2';
import CommonHelper from './Helpers/Common';
import CommonHelper2 from '../../Helpers/Common';
import ManageAddons from './Components/ManageAddons';
import AppStatusBanner from './Components/AppStatusBanner';
import querystring from 'querystring';
import {Context} from '@shopify/app-bridge-react';
import { Redirect } from "@shopify/app-bridge/actions";
// import {Error} from '@shopify/app-bridge/actions';
// import ToastMessage from './Common/ToastMessage';

const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

const ADMIN_API = "/secure/admin/api/238";
var sn_version = CommonHelper.getSnippetVersion();
export default class Index extends Component{
   static contextType = Context;

   constructor(props){
      super(props);
      this.state = {
         shopData : {},
         page_loaded: false,
         server_error: false,
         showToast: false,
         toastError: false,
         toastMsg: "",
         showUpdateBanner: false,
      }


      if(store('_wsvload')){
         var _wsvload = store('_wsvload');
         var _excluded = exclude_this_page();
         if(_excluded === false){
           if(_wsvload !== '238'){
             var shop = CommonHelper.getShop();
             store.remove('_wsvload');
             window.location.replace("/?shop="+shop+"&redirect_url="+window.location.href);
           }
         }
       }
       else{
         store('_wsvload', '238');
       }
      const parsed = querystring.parse(window.location.search);
      if(parsed._wsinit === 'clear'){
         store.remove("_wsinit");
      }
      if(parsed.cache === 'clear'){
         store.remove("_wsinit");
      }
      else if(parsed['?cache'] === 'clear'){
         try{
            let url = new URL(window.location);
            url.searchParams.delete('cache');
            window.history.replaceState({}, '', url);
         }
         catch(e){
            console.error(e);
         }

         store.remove("_wsinit");
      }
      if(parsed && (parsed.wsversion === "238" || parsed.wsversion === "2.3.8")){
         store("snippet_version", "2.3.8");
         store("wsv_stc", "1");
         store('ignore_live_file',true);
      }
   }

   toast(msg,error){
      this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error
      });
  }

   componentDidMount(){
      const CPage = getPage();
      const urlParams = new URLSearchParams(window.location.search);
      const id_token = urlParams.get('id_token');
      const session = urlParams.get('session');
      const _host = urlParams.get('host');
      if(id_token && session && _host){
         if(store("_wsinit") && CPage !== BUILD_VERSION_EXTENSION+"/pricing-plans"){
            let shopData = store("_wsinit");
            let showUpdateBanner = store("showUpdateBanner") || false;
            this.setState({
               showUpdateBanner: showUpdateBanner
            })
            try {
               shopData = JSON.parse(shopData);
               if(shopData.domain !== getShop()){
                  store.remove("_wsinit");
                  this.getShopData();
               }
            } catch (e) { }
            if(shopData.name){
               this.storeData(shopData);
               this.setState({shopData: shopData, page_loaded: true});
            }
            else{
               this.getShopData();
            }
         }
         else{
            this.getShopData();
         }
      }
      else {
         if(CPage !== "/"){
            if(store("_wsinit") && CPage !== BUILD_VERSION_EXTENSION+"/pricing-plans"){
               let shopData = store("_wsinit");
               try {
                  shopData = JSON.parse(shopData);
                  if(shopData.domain !== getShop()){
                     store.remove("_wsinit");
                     this.getShopData();
                  }
               } catch (e) { }
               if(shopData.name){
                  this.storeData(shopData);
                  this.setState({shopData: shopData, page_loaded: true});
               }
               else{
                  this.getShopData();
               }
            }
            else{
               this.getShopData();
            }
         }
      }

      // const app = this.context;
      // const unsubscribe = app.error((data) => {
      //    // type will be the error type
      //    // action will contain the original action including its id
      //    // message will contain additional hints on how to fix the error
      //    const {type, action, message} = data;
      //    // console.log("ERROR HANDLED", type, action, message);
      //    // Handle all errors here
      //    try{

      //       switch(type) {
      //          case Error.Action.PERMISSION:
      //             // Permission error

      //             // Need to check if its from resource picker and when picker is open
      //             if(action){
      //                if(action.group === "Resource_Picker" && action.type === "APP::RESOURCE_PICKER::OPEN"){
      //                   // Show error toast
      //                   let msg = "Permission denied";
      //                   if(action.payload && action.payload.resourceType){
      //                      if( action.payload.resourceType === "product" ){
      //                         msg = "Contact to store owner for products section access";
      //                      }
      //                      else if( action.payload.resourceType === "collection" ){
      //                         msg = "Contact to store owner for collections section access";
      //                      }
      //                   }

      //                   this.toast(msg, true);
      //                }
      //             }
                  
      //          break;
      //       }
            
      //    } catch(e) { }
      // });
      // Unsubscribe from all errors
      //  unsubscribe();
            
   }

   storeData(data){
      if(data){
         sn_version = CommonHelper.getSnippetVersion(data.app_version);
         store("_wsinit", JSON.stringify(data));
         store('shop', getShop());
         store('owner_email', data.email);
         store('shop_sender_email', data.customer_email);
         store('store_currency', data.currency);
         if(store("wsv_stc") !== "1"){
            store('snippet_version', sn_version);
         }
         store('ws_money_format', data.money_format);
         store('weight_unit', data.weight_unit); // store shop's default weight unit like lb,kg,grams
         if(data.shop_payment && data.shop_payment.plan_id){
            store('_wsuuid', data.shop_payment.plan_id);
         }
      }
   }

   getShopData(){
      Axios.get(ADMIN_API + "/shop/init.json").then(response => {
         if(response && response.data && response.data.shop){
            this.storeData(response.data.shop);
            store("showUpdateBanner", response?.data?.showBanner || false);
            this.setState({shopData: response.data.shop, page_loaded: true, showUpdateBanner: response?.data?.showBanner || false});
         }
         else{
            this.setState({server_error: true});
         }
      }).catch(e => {
         this.setState({server_error: true});
      })
   }

   spinnerOrSkeleton(){
      var PAGE = getPage();
      if(PAGE === BUILD_VERSION_EXTENSION+'/import-quantity-breaks' ||
      PAGE === BUILD_VERSION_EXTENSION+'/import-discount-groups' ||
      PAGE === BUILD_VERSION_EXTENSION+'/free-installation-support' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-excluded-collections' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-excluded-products' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-variants-modal' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-collections-modal' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-products-modal' ||
      PAGE === BUILD_VERSION_EXTENSION+'/selected-products-variant-picker' ||
      PAGE === BUILD_VERSION_EXTENSION+'/update-collections-modal-qb' ||  
      PAGE === BUILD_VERSION_EXTENSION+'/import-volume-discount' ||  
      PAGE === BUILD_VERSION_EXTENSION+'/import-regular-discount' ||
      PAGE === BUILD_VERSION_EXTENSION+'/select-shipping-countries' ||
      PAGE.indexOf("/ws-forms/email-template") > -1 ||
      PAGE === BUILD_VERSION_EXTENSION+'/variant-picker'
      ){
         return "spinner";
      }
      else{
         return "skeleton";
      }
   }

   render(){
      const { page_loaded, shopData, showUpdateBanner } = this.state;
      const shopPayment = shopData.shop_payment;
      const shop = getShop();
      const cs_page = getPage();

      // This will render on all pages
      // use .push() to append components
      let topLevelSection = [];

      // Deetct if store is devopment
      // By matching plan_name from shop resource, 
      // Ref: https://shopify.dev/docs/apps/billing/purchase-adjustments/free-trials#offer-free-testing-for-development-stores
      // if( shopData && shopData.plan_name && ["affiliate", "partner_test"].includes(shopData.plan_name) ){
      //    topLevelSection.push( <div>
      //     <Banner
      //         title="Development stores not supported"
      //         status="critical"
      //     >
      //       <p>The Wholesale All In One app is not compatible with Shopify Development stores. You can access app features after upgrading to a Shopify Monthly plan. Feel free to contact us with any questions.
      //          </p>
      //     </Banner>
      //   </div> );
      // }

      // Show update banner
      if(showUpdateBanner === true){
         topLevelSection.push( <div>
            <Banner
               title="Action Required"
               status="warning"
            >
               <Stack
                  vertical
                  spacing="loose"
               >
                  <p>There is an update available in the app. Only store owner or the person with relevant permissions can update the app.  No functionality will be disturbed by updating the app.</p>
                  <Button
                     onClick={() => {;
                        const app = this.context;
                        const redirect = Redirect.create(app);
                        const host = CommonHelper2.getHost();
                        const host_name = CommonHelper2.getHostName();
                        const isEmbedded = new URLSearchParams(window.location.search).get('embedded') || 0;
                        const redirect_uri = host_name + '/shopapp/2.0?shop=' + shop + '&host=' + host + '&embedded=' + isEmbedded;                           

                        store.remove("showUpdateBanner");
                        setTimeout(() => {
                           window.top.location.href = redirect_uri;
                        }, 5000);
                        redirect.dispatch(
                           Redirect.Action.REMOTE,
                           redirect_uri
                        );
                     }
                  }
                  >Update</Button>
               </Stack>
            </Banner>
         </div> );
      }

      // => PS:: Do not append sections to "topLevelSection" after this line
      topLevelSection = <div style={{marginBottom: "1rem"}}>
         <Stack vertical spacing="tight">
            {topLevelSection}
         </Stack>
      </div>


      var app_mode_section = shopData.app_mode && <div className="ws--AppMode__Section" style={cs_page !== BUILD_VERSION_EXTENSION+"/update-plans" && cs_page !== "/update-plans" ? {marginBottom:"2rem"} : {}}>
            <AppStatusBanner app_mode={shopData.app_mode} shop={shop} shopData={shopData} />
      </div>;

      if(this.spinnerOrSkeleton() === "spinner" || cs_page === BUILD_VERSION_EXTENSION+"/pricing-plans" || window.location.pathname === BUILD_VERSION_EXTENSION+"/contact-us"){
         app_mode_section = null;
      }

      // var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);

      const page_loading_spinner = <div className="snipper-container">
         <center><Spinner color="teal" size="large" /></center>
      </div>;
      if(cs_page === "/" || cs_page === "/dashboard"){
         return(
            <div>
               {topLevelSection}
               <Routing {...this.props} shopData={shopData} />
            </div>
         );
      }
      else if(page_loaded){
         if(shopPayment && shopPayment.status !== "active"){
            return(
               <div>
                  {topLevelSection}
                  <ManageAddons {...this.props} shopData={shopData} shop={shop} />
               </div>
            );
         }
         // else if(shopData && (shopData.install_status !== "completed" || !shopData.app_configured) && !CommonHelper.isAdmin()){
         //    return(
         //       <div>
         //          <InstallationStatus {...this.props} shopData={shopData} shop={shop} />
         //       </div>
         //    );
         // }
         else{

            const plan_version_updated = store('plan_version_updated');
            //console.log("plan_version_updated", plan_version_updated);

            let show_plan_updated_banner = false;
            // eslint-disable-next-line
            if(plan_version_updated && plan_version_updated == 1){
               // show the banner and remove the variable
               store.remove("plan_version_updated");

               show_plan_updated_banner = true;
            }

            return(
               <div>

                  {
                     show_plan_updated_banner &&
                     <div style={{marginBottom: "2rem"}}>
                        <Banner
                           title="Note"
                           status="success"
                           onDismiss={() => {
                              // jsut update the state, since variable is removed, its already dimissed
                              this.setState({
                                 page_loaded: true
                              });
                           }}
                        >
                           <p>Thanks for plan activation. The wholesale app team will re-integrate the latest app version into your website and send an update to you via email once it complete.</p>
                        </Banner>
                     </div>
                  }

                  {app_mode_section}
                  {topLevelSection}
                  <Routing {...this.props} shopData={shopData} shop={shop} />
               </div>
            );
         }
      }
      else if (this.state.server_error) {
         return (
            <div>
               {topLevelSection}
               <Banner
                  title="Visit Dashboard"
                  status="info"
               >
                  <p>
                     You will be taken to the dashboard shortly. If it doesn’t happen within 5 seconds, please click the button below to go there manually.
                  </p>
                  <div style={{marginTop: "1rem", textAlign: "center"}}>
                     <Button 
                        outline
                        onClick={() => {
                           var shop = CommonHelper.getShop();
                           window.location.replace("/?shop="+shop+"&redirect_url="+window.location.href+"/?cache=clear&ws=refresh");
                        }}
                        >
                        Dashboard
                     </Button>
                  </div>
               </Banner>
            </div>
         )
      }
      else{
         return(
            <div>
               {
                  this.spinnerOrSkeleton() === "skeleton"?
                  <div>
                     {app_mode_section}
                     {topLevelSection}
                     <SkeletonPage />
                  </div>
                  :page_loading_spinner
               }
            </div>
         )
      }
   }
}

function getPage() {
   return window.location.pathname;
}

function getShop() {
   return CommonHelper.getShop();
}

function exclude_this_page() {
   var exclude = false;
   var page = window.location.pathname;
   if(page === '/manual-draft-order'){
     exclude = true;
   }
   return exclude;
 }